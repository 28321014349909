<template>
  <div class="home">IN DEVELOP</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {}
})
export default class Home extends Vue {}
</script>
<style lang="scss">
.home {
  font-size: 10rem;
  font-weight: bold;
}
</style>
