<template>
  <router-view />
</template>

<style lang="scss">
@import "assets/reset";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #4e005c;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image: url("assets/bg.png");
}
</style>
